<template lang="pug">
  .app-text-expandable(
    :class="{ json, enabled, expanded }"
    @click.stop="toggle"
  ) {{ text }}
</template>

<script>
  // misc
  import { isObject, isArray } from "lodash-es"

  export default {
    props: {
      text: {
        required: true
      }
    },

    data() {
      return {
        expanded: false,
        enabled: false,
        initialText: null
      }
    },

    updated() {
      this.$nextTick(() => {
        if (this.initialText !== this.text) {
          this.setInitialText()
          this.trim()
          this.expanded = false
        }
      })
    },

    mounted() {
      this.setInitialText()
      this.trim()
    },

    computed: {
      json() {
        return isObject(this.text) || isArray(this.text)
      }
    },

    methods: {
      setInitialText() {
        this.initialText = this.text
      },

      trim() {
        const { scrollWidth, clientWidth } = this.$el
        this.enabled = scrollWidth > clientWidth
      },

      toggle() {
        if (this.enabled) {
          this.expanded = !this.expanded
        }
      }
    }
  }
</script>

<style lang="sass" scoped>
  @import "@/assets/styles/variables.sass"

  .app-text-expandable
    white-space: nowrap

    &.enabled
      overflow: hidden
      text-overflow: ellipsis
      cursor: pointer
      text-decoration: underline

      &.expanded
        text-decoration: none
        color: $default-purple
        word-break: break-all
        white-space: normal

        &.json
          white-space: pre
          text-align: left
</style>
